<template>
  <main id="login-view">
    <div class="card__header">
      <div class="card__header-text">
        <h4 class="card__title">{{ $t('sign-up') }}</h4>
      </div>
    </div>
    <template v-if="userCreated">
      <div class="card card--with-header">
        <div class="card__content">
            <p>{{ $t('user-created-confirm') }}</p>
        </div>
      </div>
    </template>
    <template v-else>
    <div class="card card--with-header">
      <div class="card__content">
        <form action="#signup" class="form" v-on:submit="onSubmit">
          <form-field v-for="item in formFields" :key="item.name" :ref="item.name" :fielddata="item" v-on:change="onFieldChange"></form-field>
        </form>
        <div class="modal__actions">
          <div class="modal__actions--left">
            &nbsp;
          </div>
          <div class="modal__actions--right">
            <button type="button" class="btn btn--new" v-on:click="onSubmit">{{ $t('submit') }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal__actions">
      <div class="modal__actions--left">
        &nbsp;
      </div>
      <div class="modal__actions--right">
        <router-link to="/signin" class="btn btn--new-secondary">{{ $t('signin') }}</router-link>
      </div>
    </div>
    </template>
  </main>
</template>
<script>
import {
  mapGetters,
  mapActions
} from 'vuex'
import FormField from '@/core/components/FormField.vue'
import {
  SIGN_UP,
  GET_COUNTRIES,
  SHOW_NOTIFICATION
} from '@/core/action-types'

export default {
  name: 'signup',
  components: {
    FormField
  },
  data: function () {
    return {
      userCreated: false,
      formValues: {
        username: '',
        password: ''
      }
    }
  },
  computed: {
    ...mapGetters([
      'authData',
      'countries'
    ]),
    // This array builds form's fields
    formFields: function () {
      return [
        {
          name: 'first_name',
          type: 'text',
          label: `${this.$t('first-name')} *`,
          title: this.$t('first-name'),
          value: '',
          required: true,
          maxlength: 50,
          pattern: null,
          class: '--w50'
        },
        {
          name: 'last_name',
          type: 'text',
          label: `${this.$t('last-name')} *`,
          title: this.$t('last-name'),
          value: '',
          required: true,
          maxlength: 50,
          pattern: null,
          class: '--w50'
        },
        {
          name: 'name',
          type: 'text',
          label: `${this.$t('company')} *`,
          title: this.$t('company'),
          value: '',
          required: true,
          maxlength: 50,
          pattern: null,
          class: '--w50'
        },
        {
          name: 'legal_name',
          type: 'text',
          label: `${this.$t('legal-name')}`,
          title: this.$t('legal-name'),
          value: '',
          required: false,
          maxlength: 50,
          pattern: null,
          class: '--w50'
        },
        {
          name: 'country',
          type: 'select',
          label: `${this.$t('country')} *`,
          title: this.$t('country'),
          dataList: [...this.countries],
          value: 'ES',
          required: true,
          class: '--w50'
        },
        {
          name: 'vat',
          type: 'text',
          label: this.$t('vat-number'),
          title: this.$t('vat-number'),
          value: '',
          required: false,
          maxlength: 50,
          pattern: null,
          class: '--w50'
        },
        {
          name: 'billing_email',
          type: 'email',
          label: this.$t('billing-email'),
          title: this.$t('billing-email'),
          value: '',
          required: false,
          maxlength: 50,
          pattern: null,
          class: '--w100'
        },
        {
          name: 'address',
          type: 'text',
          label: `${this.$t('address')} *`,
          title: this.$t('address'),
          value: '',
          required: true,
          maxlength: 50,
          pattern: null,
          class: '--w50'
        },
        {
          name: 'city',
          type: 'text',
          label: `${this.$t('city')} *`,
          title: this.$t('city'),
          value: '',
          required: true,
          maxlength: 50,
          pattern: null,
          class: '--w25'
        },
        {
          name: 'zip',
          type: 'text',
          label: `${this.$t('zip')} *`,
          title: this.$t('zip'),
          value: '',
          required: true,
          maxlength: 50,
          pattern: null,
          class: '--w25'
        },
        {
          name: 'username',
          type: 'text',
          label: `${this.$t('username-email')} *`,
          title: this.$t('username-email'),
          value: '',
          required: true,
          maxlength: 50,
          pattern: null,
          class: '--w50'
        },
        {
          name: 'password',
          type: 'password',
          label: `${this.$t('password')} *`,
          title: this.$t('password'),
          value: '',
          required: true,
          maxlength: 50,
          pattern: null,
          class: '--w25'
        },
        {
          name: 'password_confirm',
          type: 'password',
          label: `${this.$t('password-confirm')} *`,
          title: this.$t('password-confirm'),
          value: '',
          required: true,
          maxlength: 50,
          pattern: null,
          class: '--w25'
        }
      ]
    }
  },
  mounted () {
    if (this.authData === null && localStorage.getItem('access_token')) {
      this.$router.push({ path: '/signin' })
    } else {
      this.getCountries()
    }
  },
  methods: {
    ...mapActions({
      getCountries: GET_COUNTRIES,
      signUp: SIGN_UP,
      showNotification: SHOW_NOTIFICATION
    }),
    onFieldChange (data) {
      this.formValues[data.field] = data.value
    },
    onSubmit (e) {
      const passwordField = this.$refs['password'][0].$el.firstChild
      const passwordConfirmField = this.$refs['password_confirm'][0].$el.firstChild

      if (this.formValues.password.length < 8) {
        passwordField.setCustomValidity(this.$t('error-password-length'))
        passwordField.reportValidity()
      } else if (!/[a-z]/.test(this.formValues.password)) {
        passwordField.setCustomValidity(this.$t('error-password-lowercase'))
        passwordField.reportValidity()
      } else if (!/[A-Z]/.test(this.formValues.password)) {
        passwordField.setCustomValidity(this.$t('error-password-uppercase'))
        passwordField.reportValidity()
      } else if (!/[\d]/.test(this.formValues.password)) {
        passwordField.setCustomValidity(this.$t('error-password-number'))
        passwordField.reportValidity()
      } else if (!/[\W]/.test(this.formValues.password)) {
        passwordField.setCustomValidity(this.$t('error-password-special-char'))
        passwordField.reportValidity()
      } else if (this.formValues.password !== this.formValues.password_confirm) {
        passwordConfirmField.setCustomValidity(this.$t('error-password-donesnt-match'))
        passwordConfirmField.reportValidity()
      } else {
        const onComplete = function (responseData) {
          if (responseData.data.success) {
            this.showNotification({ text: this.$t('user-created'), type: 'success', icon: 'success' })
            this.userCreated = true
          } else {
            this.showNotification({ text: responseData.error.message, type: 'error', icon: 'error' })
          }
        }
        passwordConfirmField.setCustomValidity('')
        this.signUp({
          first_name: this.formValues.first_name,
          last_name: this.formValues.last_name,
          email: this.formValues.username,
          password: this.formValues.password,
          password_confirm: this.formValues.password_confirm,
          team_data: {
            name: this.formValues.name,
            vat: this.formValues.vat,
            legal_name: this.formValues.legal_name,
            address: this.formValues.address,
            city: this.formValues.city,
            zip: this.formValues.zip,
            country: this.formValues.country,
            billing_email: this.formValues.billing_email
          },
          onComplete: onComplete.bind(this)
        })
      }
      e.preventDefault()
    }
  }
}
</script>
<i18n src="@/core/i18n-common.json"></i18n>
<i18n>
{
  "en": {
    "signin": "Sign In",
    "signin_subtitle": "Enter your user and password"
  },
  "es": {
    "signin": "Identificación",
    "signin_subtitle": "Introduce tu usuario y contraseña"
  }
}
</i18n>
<style scoped>
#login-view {
  margin: 0 auto;
  width: 800px;
}
.card {
  display: block;
}
.card__content {
  padding: 20px;
}
.form {
  padding: 10px;
}
</style>
